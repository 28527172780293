<template>
  <form @submit.prevent="$emit('next')">
    <p class="has-margin-bottom-150">
      Please enter your site details below. Don’t worry if you're unsure of the
      prefix ('https' etc) – you can change this later on.
    </p>

    <site-url-field
      :url="form.siteUrl ? form.siteUrl.url : ''"
      :disabled="processing"
      required
      @change="form.siteUrl = $event"
    />

    <step-controls :back="false" :enabled="formIsValid" @back="$emit('back')" />
  </form>
</template>

<script>
export default {
  name: "SiteDetailsStep",
  components: {
    "site-url-field": () => import("@shared/sites/_siteUrlField")
  },
  props: {
    payload: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      processing: false,
      form: {
        siteUrl: this.payload.siteUrl || {},
        siteSoftware: this.payload.siteSoftware || null
      }
    };
  },
  computed: {
    formIsValid() {
      if (this.$_.isEmpty(this.form.siteUrl)) return false;
      if (this.$_.isEmpty(this.form.siteSoftware)) return false;
      return true;
    }
  },
  watch: {
    form: {
      handler(form) {
        this.$emit("payload", form);
      },
      deep: true
    }
  }
};
</script>
